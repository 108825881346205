<template>
  <div class="share">
    <img
      class="bg"
      src="http://120.24.182.152:5555/s/static/s/image_files/users_image/night.png"
      alt=""
    />
    <div class="main">
      <!-- 头部共嫩区 -->
      <div class="main-info">
        <router-link to='/home/share/person' class="buts">
          个人分享
        </router-link>
         <router-link to='/home/share/text' class="buts">
          学习笔记
        </router-link>
         <router-link to='/' class="buts">
          学习视频
        </router-link>
      </div>
      <!-- 楼层数据 -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "share",
  data() {
    return {
      
    };
  },

  methods:{
   
  }
};
</script>

<style lang='less' scoped>
.share {
  position: relative;
  .bg {
    width: 100%;
  }

  .main {
    position: absolute;
    top: 180px;
    width: 100%;
    min-height: 600px;
    background: #fff;
    border-radius: 30px 30px 0 0;

    .main-info {
      width: 100%;
      height: 60px;
       border-radius: 30px ;
       box-shadow: 1px 1px 1px rgb(179, 179, 179);
      background: rgb(255, 255, 255);
      padding: 10px 40px;
      
      .buts{
        float: left;
        padding: 0 15px;
        line-height: 40px;
        color: rgb(49, 49, 49);
      }

      .buts:hover{
        color: #3d5aeb;
        font-size: 18px;
      }
    }

    
  }
}
</style>